<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="Edit Product B2B">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input class="w-full" v-model="this.territory_name" disabled />
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 61%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>B2B Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <input
              type="radio"
              id="active"
              value="1"
              v-model="type"
              style="margin-right: 10px"
            />
            <label for="active">ZDBB</label>

            <input
              type="radio"
              id="inactive"
              value="2"
              v-model="type"
              style="margin-left: 10px"
            />
            <label for="inactive"> ZDLS</label>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Valid From - To</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <date-range-picker
              style="min-height: 40px"
              class="w-full"
              ref="picker"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd Mon yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="validity"
              :linkedCalendars="true"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ dateFormat(picker.startDate) }} -
                {{ dateFormat(picker.endDate) }}
              </template>
            </date-range-picker>
          </div>
        </div>
        <!-- <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Valid From</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              type="date"
              class="w-full"
              v-validate="'required'"
              name="ValidFrom"
              v-model="ValidFr"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('ValidFrom')"
              >{{ errors.first("ValidFrom") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Valid To</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              type="date"
              class="w-full"
              v-validate="'required'"
              name="ValidTo"
              v-model="ValidT"
            />
            <span class="text-danger text-sm" v-show="errors.has('ValidTo')">{{
              errors.first("ValidTo")
            }}</span>
          </div>
        </div> -->

        <div class="vx-row mb-6 flex-direction-row">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Min. Order Qty (SO)</span>
          </div>
          <div class="vx-col sm:w-1/5 w-full">
            <vs-input class="w-full" type="number" v-model="qtySO" :min="1" />
          </div>
          <div class="vx-col sm:w-1/5 w-full">
            <span>Handling Unit (SO)</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedUnit"
              :options="optionalUnit"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableUnit"
            />
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Status</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-switch width="120px" color="success" v-model="status">
              <span slot="on">Active</span>
              <span slot="off">Inactive</span>
            </vs-switch>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Line Items</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button size="small" class="mr-3 mb-2" @click="browseItem()"
              >Browse</vs-button
            >
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table
              :data="cartProducts"
              stripe
              style="width: 100%"
              search
              :sst="true"
              @change-page="handleChangePageItem"
              @search="handleSearchItem"
              @sort="handleSortItem"
            >
              <template slot="thead">
                <vs-th>SKU</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Handling Unit (SKU)</vs-th>
                <vs-th>Categories</vs-th>
                <vs-th>Min. Order Qty (SKU)</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(dt, index) in data">
                  <vs-td style="align-item-center">{{ dt.skuCode }}</vs-td>
                  <vs-td style="align-item-center">{{ dt.Name }} </vs-td>
                  <vs-td style="align-item-center">{{ dt.Unit }}</vs-td>
                  <vs-td style="align-item-center">{{ dt.categories }}</vs-td>
                  <vs-td style="align-item-center">
                    <vs-input type="number" v-model.number="dt.qty" :min="1" />
                  </vs-td>
                  <vs-td style="align-item-center">
                    <feather-icon
                      title="Delete"
                      icon="TrashIcon"
                      svgClasses="w-5 h-5 text-danger stroke-current"
                      class="ml-2"
                      @click="deleteItemLine(index)"
                    />
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-6/12 w-full" v-if="update">
            <vs-button class="mr-3 mb-2" v-on:click="SubmitForm"
              >Save</vs-button
            >
            <!-- <vs-button v-if="quantityChecked != true" class="mr-3 mb-2" v-on:click="CheckQty()">CheckQty</vs-button> -->
            <vs-button color="danger" class="mr-3 mb-2" @click="CancelForm()"
              >Cancel</vs-button
            >
          </div>
        </div>
      </div>
      <div
        v-if="this.optionItemAvailableShow"
        class="vx-col sm:w-1/2 w-full mb-base"
      >
        <div class="vx-row mb-6">
          <h3>Item Unit</h3>
        </div>
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="items"
          :max-items="length"
          :total="total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.start }} - {{ this.end }} of {{ this.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th sort-key="sku">SKU</vs-th>
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="unit">Unit</vs-th>
            <vs-th sort-key="unit">Supplier</vs-th>
            <vs-th sort-key="unit">Categories</vs-th>
            <vs-th sort-key="action">Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data.records"
            >
              <vs-td :data="data.records[indextr].sku_code">{{
                data.records[indextr].sku_code
              }}</vs-td>
              <vs-td :data="data.records[indextr].item_name">{{
                data.records[indextr].item_name
              }}</vs-td>
              <vs-td :data="data.records[indextr].unit_name">{{
                data.records[indextr].unit_name
              }}</vs-td>
              <vs-td :data="data.records[indextr].categories_name">{{
                data.records[indextr].categories_name
              }}</vs-td>
              <vs-td :data="data.records[indextr].categories_name">{{
                data.records[indextr].categories_name
              }}</vs-td>
              <vs-td style="align-item-center">
                <feather-icon
                  title="Add"
                  icon="PlusIcon"
                  svgClasses="w-5 h-5 text-success stroke-current"
                  class="ml-2"
                  @click="addItemLine(tr)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import PlusBox from "vue-material-design-icons/PlusBox.vue";

import draggable from "vuedraggable";

import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    draggable,
    ArrowUpDownBold,
    PlusBox,
    Datepicker,
    vSelect,
    DateRangePicker,
  },
  mounted() {
    // console.log("mounted - form : ", this.territoryCode, this.territoryId, this.selectedReservation, this.optionTerritory);
    this.update = true;
    this.getTerritory();
    this.ProductItem = this.$route.params.id;
    this.getProductItems(this.ProductItem);
    this.getUnit();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
  watch: {
    search: function () {
      if (this.optionItemAvailableShow) {
        this.getOptionItemAvailable();
      }
    },
    itemsQuantity: function () {
      this.quantityChecked = false;
    },
  },
  data: () => ({
    update: false,
    quantityChecked: false,
    external: false,

    optionItemAvailable: [{}],

    optionCustomerShow: false,
    optionItemAvailableShow: false,

    limits: [10, 25, 50, 100, "All"],
    limitShow: 10,
    drawData: 0,
    isActive: 1,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,

    items: [],
    optionTerritory: [],
    selectedTerritory: {},
    start: 1,
    end: 0,
    length: 10,
    page: 1,
    search: "",
    itemSearch: "",
    order: "id",
    sort: "desc",
    total: 0,
    totalPage: 0,
    totalSearch: 0,
    validity: {
      startDate: null,
      endDate: null,
    },
    minValidFrom: null,
    maxValidTo: null,
    companyID: 0,
    ProductItem: [],
    ID: 0,
    cartProducts: [],
    itemUnitID: [],
    qtySO: 0,
    selectedUnit: { Name: "" },
    optionalUnit: [],
    status: true,
    type: 1,
  }),
  methods: {
    handleBack() {
      this.$router.push({
        name: "product-back-to-back",
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    CancelForm() {
      this.update = true;
      this.quantityChecked = false;
      this.external = false;

      this.cartProducts = [];

      this.itemsUom = [];
      this.itemsQuantity = [];
      this.selectedUnit = { Name: "" };

      this.optionItemAvailable = [{}];

      this.optionItemAvailableShow = false;

      this.$emit("selectReservation", {});
    },
    browseItem() {
      this.search = "";
      this.optionItemAvailableShow = true;
      this.optionCustomerShow = false;
      this.getOptionItemAvailable();
      this.scrollToTop();
    },
    addItemLine(item) {
      // console.log("ini itemLine>>>",);
      this.quantityChecked = false;
      let check = this.itemUnitID.filter((val) => {
        return item.id == val;
      });
      console.log(check);

      if (check.length == 0) {
        this.cartProducts.push({
          itemId: item.id,
          skuCode: item.sku_code,
          Name: item.item_name,
          Unit: item.unit_name,
          categories: item.categories_name,
        });
        this.itemUnitID.push(item.id);
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Reservation item line ",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    deleteItemLine(index) {
      this.quantityChecked = false;
      if (this.external == false) {
        this.cartProducts.splice(index, 1);
        this.itemUnitID.splice(index, 1);
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Reservation item line",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleChangelength(val) {
      this.length = val == "All" ? this.total : val;
      this.page = 1;
      this.getOptionItemAvailable();
    },
    getOptionItemAvailable(page = 1) {
      this.isActive = page;
      // this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-btwob-rules/table", {
          params: {
            territory_id: this.selectedTerritory,
            length: this.length,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          console.log(">>>>>>>>", resp);
          if (resp.code == 200) {
            let company_id = this.$store.state.user.currentUser.company_id;

            this.optionItemAvailable = resp.data.ItemsAvailable;
            this.total = resp.data.total_record;
            this.totalPage = resp.data.total_page;
            this.totalSearch = resp.data.total_record_search;
            this.length = resp.data.total_record_per_page;
            this.items = resp.data;
            this.CompanyID = company_id;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Item Available",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    SubmitForm() {
      const id = this.$route.params.id;
      if (this.cartProducts.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      if (!this.validity.startDate || !this.validity.endDate) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Valid from and valid to is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      this.$vs.loading();
      this.$http
        .put("/api/sfa/v1/product-btwob-rules/" + id + "/update", {
          valid_from: this.validity.startDate
            ? moment(this.validity.startDate).format("YYYY-MM-DD")
            : null,
          valid_to: this.validity.endDate
            ? moment(this.validity.endDate).format("YYYY-MM-DD")
            : null,
          territory_id: this.selectedTerritory,
          unit_id: this.selectedUnit.id,
          company_id: this.companyID,
          item_unit: this.cartProducts,
          qty_total: parseInt(this.qtySO),
          ID: parseInt(this.$route.params.id),
          status: this.status
            ? this.status == true
              ? "Active"
              : "Inactive"
            : "Inactive",
          type: parseInt(this.type),
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            // this.CancelForm();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/route-plan/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    setStartEnd() {
      let valStart = this.length * this.page - this.length + 1;
      if (valStart > this.total) {
        valStart = 1;
      }
      if (this.total == 0) {
        valStart = 0;
      }
      let valEnd = this.length * this.page;
      if (valEnd > this.total) {
        valEnd = this.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.totalSearch;
      }

      this.start = valStart;
      this.end = valEnd;
    },
    handleSearch(searching) {
      this.search = searching;
      this.page = 1;
      this.getOptionItemAvailable(this.isActive);
    },
    handleChangePage(page) {
      this.page = page;
      this.getOptionItemAvailable(this.isActive);
    },
    handleSort(key, active) {
      this.order = key;
      this.sort = active;
      this.getOptionItemAvailable(this.isActive);
    },
    getProductItems(id) {
      this.$http
        .get("/api/sfa/v1/product-btwob-rules/" + id + "/edit", {
          params: {
            page: this.page,
            search: this.itemSearch,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            console.log(">>>>>>>", resp.data);
            this.items = resp.data.records;
            this.cartProducts = [];
            this.companyID = resp.data.data_product_btob.CompanyID;
            this.territory_name = resp.data.territory.name;
            this.unit_name = resp.data.unit.Name;
            this.selectedTerritory = resp.data.data_product_btob.TerritoryID;
            this.selectedUnit = resp.data.unit;
            this.status = resp.data.data_product_btob.Status
              ? resp.data.data_product_btob.Status
                ? true
                : false
              : false;
            this.type = resp.data.data_product_btob.Type
              ? resp.data.data_product_btob.Type
                ? resp.data.data_product_btob.Type
                : 1
              : 1;
            this.validity.startDate = moment(
              resp.data.data_product_btob.ValidFrom
            ).format("YYYY-MM-DD");
            this.validity.endDate = moment(
              resp.data.data_product_btob.ValidTo
            ).format("YYYY-MM-DD");
            this.qtySO = resp.data.data_product_btob.QuantitySO;
            for (let index = 0; index < resp.data.Data_Item.length; index++) {
              this.itemUnitID.push(resp.data.Data_Item[index].id);
              this.cartProducts.push({
                itemId: resp.data.Data_Item[index].id,
                skuCode: resp.data.Data_Item[index].sku_code,
                Name: resp.data.Data_Item[index].name_item,
                Unit: resp.data.Data_Item[index].unit.Name,
                qty: resp.data.records[index].MinQty,
                categories: resp.data.Data_Item[index].categories_name,
              });
            }
          }
        });
    },
    handleChangePageItem(page) {
      this.page = page;
      this.getProductItems(this.$route.params.id);
    },
    handleSearchItem(searching) {
      this.itemSearch = searching;
      this.page = 0;
      this.getProductItems(this.$route.params.id);
    },
    handleSortItem(key, active) {
      this.order = key;
      this.sort = active;
      this.getProductItems(this.$route.params.id);
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
    getUnit() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-btwob-rules/unit", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalUnit = resp.data.records;
              if (this.optionalUnit.length > 0) {
                this.selectedUnit = this.optionalUnit[0];
              } else {
                this.optionalUnit = [];
                this.selectedUnit = { Name: "" };
              }
            } else {
              this.optionalUnit = [];
              this.selectedUnit = { Name: "" };
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableUnit({ Name }) {
      return `${Name}`;
    },
  },
};
</script>
